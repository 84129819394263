import * as React from "react";
import { Container, Header, Alert, SpaceBetween, Button, ColumnLayout, Box, StatusIndicator } from "@amzn/awsui-components-react";
import { useParams } from 'react-router-dom';
import ReactJson from "react-json-view";
import axios, { AxiosInstance } from "axios";
import { getApiClient } from '../../proxy/uiautomationclient';
import withLoading from '../../common/Loading';
import {StatusIndicatorProps} from "@amzn/awsui-components-react/polaris/status-indicator/internal";

export interface TestExecutionFetcherProps {
  setLoading: (loading: boolean) => void;
}

function getStatusIndicator(status: string): StatusIndicatorProps.Type {
  if (status === 'queued') return 'pending';
  if (status === 'running') return 'in-progress';
  if (status === 'success') return 'success';

  return "error";
}

function TestExecutionFetcher({ setLoading } : TestExecutionFetcherProps) {
  const { testId, executionId } = useParams<{ testId: string, executionId: string }>();
  const [client, setClient] = React.useState<AxiosInstance | undefined>(undefined);
  const [response, setResponse] = React.useState<any>();
  const [inputs, setInputs] = React.useState<any>();
  const [outputs, setOutputs] = React.useState<any>();
  const [responseError, setResponseError] = React.useState<string>();
  const [visible, setVisible] = React.useState(false);

  if (!client) {
    setClient(() => {
        return getApiClient() as AxiosInstance;
     });
  }

  React.useEffect(() => {
    async function fetchTestExecution() {
      setLoading(true);
      try {
        const response = await client?.post('/getTestExecutionResult', { "testId": testId, "executionId": executionId});
        setResponse(response?.data);
        if (response?.data.s3ObjectURL) {
          const s3Results = await axios.get(response?.data.s3ObjectURL);
          setOutputs(s3Results?.data.outputs);
        }
        if (response?.data.status !== 'queued') {
          //input file is created at the same time as the output file
          const inputs = await axios.get(response?.data.s3InputsURL);
          setInputs(inputs?.data.inputs);
        }
      } catch (error) {
        setResponseError(JSON.stringify(error));
        setVisible(true);
      } finally {
        setLoading(false);
      }
    }
    fetchTestExecution()
  }, [client, testId, executionId, setLoading])
  
  const retryExecution = async() => {
    setLoading(true);
    try {
      const response = await client?.post('/retryTestExecution', { "testId": testId, "executionId": executionId });
      setResponse(response?.data);
      return response?.data.results;
    } catch (error) {
      setResponseError(JSON.stringify(error));
      setVisible(true);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container
      header={
        <Header 
          variant="h2"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                href={response?.s3ObjectURL}
                variant="link"
                target="_blank"
              >
                Results Object
              </Button>
              <Button
                href={response?.s3VideoURL}
                target="_blank"
                variant="link"
              >
                Video
              </Button>
              <Button
                href={response?.s3ScreenShotURL}
                target="_blank"
                variant="link"
                disabled = {response?.status !== 'fail'}
              >
                Screenshot
              </Button>
              <Button
                onClick={retryExecution}
                variant="primary"
              >
                Retry
              </Button>
            </SpaceBetween>
          }
        >
          Test Execution Results
        </Header>
      }>
      <Alert
        onDismiss={() => setVisible(false)}
        visible={visible}
        dismissAriaLabel="Close alert"
        header="Response error"
        type="error">
          {responseError}
      </Alert>
      <ColumnLayout columns={3} variant="text-grid">
        <SpaceBetween size="l">
          <div>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
              Test Case Id
            </Box>
            <div>{ testId }</div>
          </div>
          <div>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
              Execution Id
            </Box>
            <div>{ executionId }</div>
          </div>
          <div>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
              Requested By
            </Box>
            <div>{ response?.requestedBy ?? "Placeholder" }</div>
          </div>
          <div>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
              Status
            </Box>
            <StatusIndicator type={getStatusIndicator(response?.status)}>
                  {response?.status}
            </StatusIndicator>
          </div>
        </SpaceBetween>

        <SpaceBetween size="l">
          <div>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
              Start Execution Time
            </Box>
            <div>{ response?.startedTestsAt ?? "" }</div>
          </div>
          <div>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
              End Execution Time
            </Box>
            <div>{ response?.endedTestsAt ?? "" }</div>
          </div>
          <div>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
              Duration (ms)
            </Box>
            <div>{ response?.totalDuration ?? "" }</div>
          </div>
        </SpaceBetween>

        <SpaceBetween size="l">
          <div>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
              Inputs for this run
            </Box>
            <ReactJson displayObjectSize={false} name={null} src={inputs || {}} />
          </div>
          <div>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
              Outputs for this run
            </Box>
            <ReactJson displayObjectSize={false} name={null} src={outputs || {}} />
          </div>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
}

export default withLoading(TestExecutionFetcher, "Loading Test Execution page");